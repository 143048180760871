import { PagingMixin } from '@/mixins/paging';
import { OsTableOption, OsTableColumn } from '@/components/os-table/os-table';
import { OperationOption } from '@/components/os-table-operation/os-table-operation';
import { projectProductService, projectService } from '@/api';
import { dateFormat, decimalToNumber6, messageError, messageErrors, translation } from '@/utils';
import { TagsViewModule } from "@/store/modules/tags-view";
import { Component, ProvideReactive } from "vue-property-decorator";
import { FieldOption, FieldOptionList, SaveProjectProduct } from '@/resource/model';
import { NumberAssignmentTypeEnum, PrepressChangeColorCraftsEnum, PrepressLayoutCraftsEnum, ProjectItemTypeEnum, StringAssignmentTypeEnum } from '@/resource/enum';
import { mixins } from 'vue-class-component';
import { Message } from 'element-ui';
import { BatchSetupData, SelectProjectItem } from '@/views/dialogs';
import { ApiError } from '@/api/axios';
import Decimal from 'decimal.js';

@Component({
  name: "ProductBatchCopy",
  components: {
    SelectProjectItem,
    BatchSetupData
  }
})
export default class ProductBatchCopy extends mixins(PagingMixin) {
  /**
   * 表格key，每次更新表格数据时取反更新key
   */
  public tableKey = true;

  public submitLoading = false;

  public pageLoading = true;

  /**
   * 批量修改组件显示控制
   */
  public batchSetupDataVisible = false;

  /**
   * 批量修改组件配置
   */
  public fieldOptions: Array<FieldOptionList<SaveProjectProduct>> = [
    {
      fieldName: 'visibleWidth',
      fieldLabel: translation('projectProduct.visibleWidth'),
      disabled: false,
      fieldType: 'number'
    },
    {
      fieldName: 'visibleHeight',
      fieldLabel: translation('projectProduct.visibleHeight'),
      disabled: false,
      fieldType: 'number'
    },
    {
      fieldName: 'finishWidth',
      fieldLabel: translation('projectProduct.finishWidth'),
      disabled: false,
      fieldType: 'number'
    },
    {
      fieldName: 'finishHeight',
      fieldLabel: translation('projectProduct.visibleHeight'),
      disabled: false,
      fieldType: 'number'
    },
    {
      fieldName: 'count',
      fieldLabel: translation('projectProduct.count'),
      disabled: false,
      fieldType: 'number'
    },
    {
      fieldName: 'topPocketSize',
      fieldLabel: translation('projectProduct.topPocketSize'),
      disabled: false,
      fieldType: 'number'
    },
    {
      fieldName: 'bottomPocketSize',
      fieldLabel: translation('projectProduct.bottomPocketSize'),
      disabled: false,
      fieldType: 'number'
    },
    {
      fieldName: 'pointName',
      fieldLabel: translation('projectProduct.pointName'),
      disabled: false,
      fieldType: 'string'
    },
    {
      fieldName: 'remark',
      fieldLabel: translation('projectProduct.remark'),
      disabled: false,
      fieldType: 'string'
    },
    {
      fieldName: 'prepressPicture',
      fieldLabel: translation('projectProduct.prepressPicture'),
      disabled: false,
      fieldType: 'string'
    },
    {
      fieldName: 'prepressRemark',
      fieldLabel: translation('projectProduct.prepressRemark'),
      disabled: false,
      fieldType: 'string'
    },
    {
      fieldName: 'prepressSubmitCount',
      fieldLabel: translation('projectProduct.prepressSubmitCount'),
      disabled: false,
      fieldType: 'number'
    },
    {
      fieldName: 'requiredDeliveryTime',
      fieldLabel: translation('projectProduct.requiredDeliveryTime'),
      disabled: false,
      fieldType: 'datetime'
    },
    {
      fieldName: 'requiredArriveTime',
      fieldLabel: translation('projectProduct.requiredArriveTime'),
      disabled: false,
      fieldType: 'datetime'
    }
  ]

  /**
   * 调打控制
   */
  public selectProductVisible: boolean = false;

  /**
   * 表格配置项
   */
  public tableOption: OsTableOption<SaveProjectProduct & {_id: string}> = {
    loading: false,
    data: [],
    fit: true,
    closeAdaptiveHeight: false,
    rowKey: (row: any) => {
      return row._id;
    }
  };

  /**
   * 表格列字段配置
   */
  public defaultColumnOptions: Array<OsTableColumn<SaveProjectProduct&{_id: string,index: number}>> = [
    {
      type: 'selection',
      prop: '_id',
      label: '',
      reserveSelection: true,
      fixed: true
    },
    {
      type: 'index',
      prop: 'index',
      label: 'common.index',
      fixed: true
    },

    {
      prop: 'requiredDeliveryTime',
      label: 'projectProduct.requiredDeliveryTime',
      minWidth: '150px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        return dateFormat((row as SaveProjectProduct).requiredDeliveryTime, 'YYYY-MM-DD HH:mm');
      }
    },
    {
      prop: 'requiredArriveTime',
      label: 'projectProduct.requiredArriveTime',
      minWidth: '150px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        return dateFormat((row as SaveProjectProduct).requiredArriveTime, 'YYYY-MM-DD HH:mm');
      }
    },
    {
      prop: 'itemType',
      label: 'projectProduct.itemType',
      minWidth: '100px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        const itemType = (row as SaveProjectProduct).itemType;
        return translation(`projectItemType.${ProjectItemTypeEnum[itemType]}`);
      }
    },
    {
      prop: 'pointName',
      label: 'projectProduct.pointName',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'platformProductName',
      label: 'projectProduct.platformProductName',
      minWidth: '300px',
      showOverflowTooltip: true
    },
    {
      prop: 'customerProductName',
      label: 'projectProduct.customerProduct',
      minWidth: '200px',
      showOverflowTooltip: true
    },
    {
      prop: 'backendCrafts',
      label: 'projectProduct.backendCrafts',
      showOverflowTooltip: true,
      minWidth: '180px'
    },
    {
      prop: 'topPocketSize',
      label: 'prepress.topPocketSize',
      showOverflowTooltip: true,
      minWidth: '120px'
    },
    {
      prop: 'bottomPocketSize',
      label: 'prepress.bottomPocketSize',
      showOverflowTooltip: true,
      minWidth: '120px'
    },
    {
      prop: 'count',
      label: 'projectProduct.count',
      showOverflowTooltip: true,
      minWidth: '100px'
    },
    {
      prop: 'saleUnitName',
      label: 'projectProduct.saleUnitName',
      minWidth: '80px',
      showOverflowTooltip: true
    },
    {
      prop: 'visibleWidth',
      label: 'customerPoint.visibleWidth',
      minWidth: '120px',
      showOverflowTooltip: true
    },
    {
      prop: 'visibleHeight',
      label: 'customerPoint.visibleHeight',
      minWidth: '120px',
      showOverflowTooltip: true
    },
    {
      prop: 'finishWidth',
      label: 'customerPoint.finishWidth',
      minWidth: '120px',
      showOverflowTooltip: true
    },
    {
      prop: 'finishHeight',
      label: 'customerPoint.finishHeight',
      minWidth: '120px',
      showOverflowTooltip: true
    },
    {
      prop: 'unitArea',
      label: 'projectProduct.unitArea',
      minWidth: '120px',
      showOverflowTooltip: true
    },
    {
      prop: 'totalArea',
      label: 'projectProduct.totalArea',
      minWidth: '120px',
      showOverflowTooltip: true
    },
    {
      prop: 'prepressPicture',
      label: 'projectProduct.prepressPicture',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'prepressRemark',
      label: 'projectProduct.prepressRemark',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'prepressCrafts',
      label: 'projectProduct.prepressCrafts',
      minWidth: '180px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        if (!(row as SaveProjectProduct).prepressCrafts) {
          return '--';
        }
        if ((row as SaveProjectProduct).itemType === ProjectItemTypeEnum.changeColor) {
          return translation(
            `prepressChangeColorCrafts.${PrepressChangeColorCraftsEnum[(row as SaveProjectProduct).prepressCrafts]}`
          );
        }
        return translation(
          `prepressLayoutCrafts.${PrepressLayoutCraftsEnum[(row as SaveProjectProduct).prepressCrafts]}`
        );
      }
    },
    {
      prop: 'prepressSubmitCount',
      label: 'projectProduct.prepressSubmitCount',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'remark',
      label: 'projectProduct.remark',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'platformPrepressFlag',
      label: 'projectProduct.platformPrepressFlag',
      minWidth: '100px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        return (row as SaveProjectProduct).platformPrepressFlag ? translation('projectProduct.flagOpen') : translation('projectProduct.flagClose');
      }
    },
    {
      prop: 'prepressDesignFlag',
      label: 'projectProduct.designTypography',
      minWidth: '100px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        return (row as SaveProjectProduct).prepressDesignFlag ? translation('projectProduct.flagOpen') : translation('projectProduct.flagClose');
      }
    },
    {
      prop: 'prepressFinishFlag',
      label: 'projectProduct.ProductionFile',
      minWidth: '100px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        return (row as SaveProjectProduct).prepressFinishFlag ? translation('projectProduct.flagOpen') : translation('projectProduct.flagClose');
      }
    },
    {
      prop: 'logisticsFlag',
      label: 'projectProduct.logisticsFlag',
      minWidth: '100px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        return (row as SaveProjectProduct).logisticsFlag ? translation('projectProduct.flagOpen') : translation('projectProduct.flagClose');
      }
    },
    {
      prop: 'installFlag',
      label: 'projectProduct.installFlag',
      minWidth: '100px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        return (row as SaveProjectProduct).installFlag ? translation('projectProduct.flagOpen') : translation('projectProduct.flagClose');
      }
    },
    {
      prop: 'taxRateFlag',
      label: 'customerProduct.taxRateFlag',
      minWidth: '100px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        return (row as SaveProjectProduct).taxRateFlag ? translation('common.yes') : translation('common.no');
      }
    },
    {
      prop: 'areaPriceFlag',
      label: 'projectProduct.areaPriceFlag',
      minWidth: '100px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        return (row as SaveProjectProduct).areaPriceFlag ? translation('common.yes') : translation('common.no');
      }
    },
    {
      prop: 'priceCount',
      label: 'projectProduct.priceCount',
      minWidth: '100px'
    },
    {
      prop: 'salePrice',
      label: 'projectProduct.salePrice',
      minWidth: '100px'
    },
    {
      prop: 'priceUnitName',
      label: 'projectProduct.priceUnitName',
      minWidth: '100px'
    },
    {
      prop: 'priceIncludingTax',
      label: 'projectProduct.priceIncludingTax',
      minWidth: '100px'
    },
    {
      prop: 'taxRate',
      label: 'projectProduct.taxRate',
      minWidth: '80px'
    },
    {
      prop: 'totalPrice',
      label: 'projectProduct.totalPrice',
      minWidth: '150px'
    },
    {
      prop: 'taxAmount',
      label: 'projectProduct.taxAmount',
      minWidth: '150px'
    },
    {
      prop: 'amountIncludingTax',
      label: 'projectProduct.amountIncludingTax',
      minWidth: '150px'
    }
  ]
  
  /**
   * 表格配置项
   */
  public operationOptions: Array<OperationOption> = [
    {
      type: 'primary',
      slot: 'start',
      label: 'button.selectOrder',
      operationType: 'add',
      icon: 'el-icon-plus',
      handleClick: this.selectOrder
    },
    {
      type: 'primary',
      slot: 'start',
      label: 'button.edit',
      operationType: 'edit',
      icon: 'el-icon-edit',
      disabled: true,
      handleClick: this.openEditDialog
    },
    {
      type: 'danger',
      slot: 'start',
      label: 'button.delete',
      operationType: 'delete',
      icon: 'el-icon-remove',
      disabled: true,
      handleClick: this.deleteProducts
    }
  ]

  /**
   * 存放选中项变量
   */
  public selectedRows: Array<SaveProjectProduct&{_id: string}> = [];

  @ProvideReactive()
  private projectId = Number(this.$route.query.projectId);

  @ProvideReactive()
  private projectCode = "";

  private projectName = "";

  @ProvideReactive()
  private customerId!: number;

  public created(): void {
    this.init();
  }

  public activated(): void {
    if (Number(this.$route.query.projectId) !== this.projectId) {
      this.projectId = Number(this.$route.query.projectId);
      this.init();
    }
  }

  /**
   * 批量修改回调
   * @param field 修改结果
   */
  public afterBatchEdit(field: Array<FieldOption>): void {
    const errors: Array<ApiError> = [];
    this.selectedRows.forEach(item => {
      const entries = new Map(Object.entries(item));
      // let message = '';
      field.forEach(cur => {
        if (cur.fieldType === 'string') {
          switch (cur.assignmentType) {
            case StringAssignmentTypeEnum.update:
              entries.set(cur.fieldName, cur.fieldValue);
              break;
            case StringAssignmentTypeEnum.increase:
              entries.set(cur.fieldName, `${entries.get(cur.fieldName) || ''}${cur.fieldValue}`);
              break;
            default:
              break;
          }
        } else if (cur.fieldType === 'number') {
          switch (cur.assignmentType) {
            case NumberAssignmentTypeEnum.equal:
              entries.set(cur.fieldName, cur.fieldValue);
              break;
            case NumberAssignmentTypeEnum.add:
              entries.set(cur.fieldName, entries.get(cur.fieldName) + cur.fieldValue);
              break;
            case NumberAssignmentTypeEnum.sub:
              entries.set(cur.fieldName, entries.get(cur.fieldName) - cur.fieldValue);
              break;
            default:
              break;
          }
        } else {
          entries.set(cur.fieldName, cur.fieldValue);
        }
        Object.assign(item, Object.fromEntries(entries));
      });

      // 修改尺寸后同步财务信息
      this.calculateFinancialInfo(item);
    });
    if (errors.length > 0) {
      messageErrors(errors);
    }
    this.batchSetupDataVisible = false;
  }

  public getContent(): String {
    return `【${this.projectCode}】${this.projectName} - ${translation('route.productBatchCopy')}`
  }

  /**
   * 返回处理
   */
  public goBack(): void {
    this.$router.go(-1);
    TagsViewModule.DelView(this.$route);
  }

  /**
   * 表格选中行变更，更新选中数据变量
   * @param selectedData 选中的数据
   */
  public handleSelectionChange(selectedData: Array<SaveProjectProduct&{_id:string}>): void {
    this.selectedRows = selectedData;
    
    this.operationOptions.forEach(item => {
      if(['delete', 'edit'].includes(item.operationType)) {
        item.disabled = this.selectedRows.length === 0;
      }
    })
  }

  /**
   * 选择产品成功回调
   * @param list 选择产品数据
   */
  public selectProductCallback(list: Array<SaveProjectProduct&{_id:string}>): void {
    this.tableOption.loading = true;
    try {
      const newList = JSON.parse(JSON.stringify(list));
      newList.forEach((item : SaveProjectProduct) => {
        item.requiredDeliveryTime = "";
        item.requiredArriveTime = "";
      });

      this.tableOption.data.push(...newList);
      this.tableKey = !this.tableKey;
    } catch (error) {
      messageError(error);
    } finally {
      this.tableOption.loading = false;
    }
  }

  /**
   * 提交数据
   */
  public submit(): void {
    this.submitLoading = true;
    if(!this.validate()) {
      this.submitLoading = false;
      return;
    }
    
    projectProductService
      .batch(this.tableOption.data)
      .then(() => {
        Message.success(translation('operationRes.operationSuccess'));
        this.goBack();
      })
      .catch(error => {
        messageErrors(error);
      })
      .finally(() => {
        this.submitLoading = false;
      });
  }

  /**
   * 页面初始化
   */
  private async init(): Promise<void> {
    this.pageLoading = true;
    const project = await projectService.getById(this.projectId);
    this.projectCode = project.projectCode;
    this.projectName = project.projectName;
    this.customerId = project.customerId;

    this.pageLoading = false;
  }

  /**
   * 选择订单控制
   */
  private selectOrder(): void {
    this.selectProductVisible = true;
  }

  /**
   * 删除选中产品
   */
  private deleteProducts(): void {
    const selectIds = this.selectedRows.map(item => item._id);
    this.tableOption.data = this.tableOption.data.filter(item => !selectIds.includes(item._id))
    this.tableKey = !this.tableKey;
  }

  private openEditDialog(): void {
    this.batchSetupDataVisible = true;
  }

  /**
   * 财务信息计算
   * @returns
   */
  private calculateFinancialInfo(rowData: SaveProjectProduct): SaveProjectProduct {
    // 计算单位面积
    rowData.unitArea =
      decimalToNumber6(new Decimal(rowData.finishWidth as number).mul(new Decimal(rowData.finishHeight as number))) /
      1000000;
    // 计算总面积
    rowData.totalArea = decimalToNumber6(
      new Decimal(rowData.unitArea as number).mul(new Decimal(rowData.count as number))
    );
    // 计算计价数量
    if (rowData.areaPriceFlag === 1) {
      // 计价数量等于计价面积
      rowData.priceCount = rowData.totalArea;
    } else {
      rowData.priceCount = rowData.count;
    }

    // 税额 = 单价 * (税率/100) * 计价数量
    rowData.taxAmount = decimalToNumber6(
      new Decimal(rowData.salePrice!)
        .mul(new Decimal(rowData.taxRate!))
        .div(new Decimal(100))
        .mul(new Decimal(rowData.priceCount!))
    );
    // 未税金额 = 单价 * 计价数量
    rowData.totalPrice = decimalToNumber6(new Decimal(rowData.salePrice!).mul(new Decimal(rowData.priceCount!)));
    // 含税金额 = 未税金额 +  税额
    rowData.amountIncludingTax = decimalToNumber6(
      new Decimal(rowData.totalPrice!).add(new Decimal(rowData.taxAmount!))
    );

    return rowData;
  }

  /**
   * 提交前验证
   * @returns 验证结果
   */
  private validate(): boolean {
    if (this.tableOption.data?.length === 0) {
      Message.warning(`${translation('没有可提交的数据')}`);
      return false;
    }
    const errors: Array<ApiError> = [];
    this.tableOption.data?.forEach((product: SaveProjectProduct, index: number) => {
      let message = '';
      if (!product.visibleWidth || product.visibleWidth <= 0 || !product.visibleHeight || product.visibleHeight <= 0) {
        message = `[${translation('projectProduct.inputVisibleSize')}]`;
      } else if (product.visibleWidth > product.finishWidth! || product.visibleHeight > product.finishHeight!) {
        // 可视尺寸不能大于完成尺寸
        message = message + `[${translation('prepress.verifyVisibleSize')}]`;
      }
      if (product.pointName!.length > 200) {
        // 点位名称超出长度
        message = message + `[点位名称：超出长度范围]`;
      }
      if (product.remark!.length > 200) {
        message = message + `[备注信息：超出长度范围]`;
      }

      if (!product.prepressPicture) {
        message = message + `[印前画面：未填写]`;
      } else if (product.prepressPicture.length > 200) {
        message = message + `[印前画面：超出长度范围]`;
      }
      if (!product.prepressRemark) {
        message = message + `[印前说明：未填写]`;
      } else if (product.prepressPicture!.length > 200) {
        message = message + `[印前说明：超出长度范围]`;
      }
      if (product.prepressPicture && product.prepressPicture.length > 200) {
        message = message + `[改稿说明：超出长度范围]`;
      }

      if (!product.prepressSubmitCount || product.prepressSubmitCount <= 0) {
        message = message + `[交稿数量：要求大于等于1]`;
      }

      message += this.validateTime(product);

      message += this.validatePocketSize(product);

      if (message) {
        errors.push({
          message: `【第${index + 1}行】: ${message} `,
          level: 1
        });
      }
    });
    if (errors.length > 0) {
      messageErrors(errors);
      return false;
    }
    return true;
  }

  private validateTime(product: SaveProjectProduct): string {
    let message = "";
    if (!product.requiredDeliveryTime) {
      message = message + `[要求出货时间：${translation('orderChange.requiredDeliveryTimeInputTip')}]`;
    }

    if (!product.requiredArriveTime) {
      message = message + `[要求到货时间：${translation('orderChange.requiredArriveTimeInputTip')}]`;
    }

    if (new Date(product.requiredDeliveryTime) < new Date()) {
      message = message + `[要求出货时间：${translation('projectProduct.cannotLessThanCurrentTime')}]`;
    }

    if (new Date(product.requiredArriveTime) < new Date()) {
      message = message + `[要求到货时间：${translation('projectProduct.cannotLessThanCurrentTime')}]`;
    }

    if (new Date(product.requiredDeliveryTime).getTime() > new Date(product.requiredArriveTime).getTime()){
      message = message + `[${translation('orderChange.timeErrorTip')}]`;
    }

    return message;
  }

  private validatePocketSize(product: SaveProjectProduct): string {
    let message = "";
    if (product.pocketFlag === 1) {
      if (product.topPocketSize < 0 && product.bottomPocketSize < 0) {
        message += message + `[口袋尺寸：不能全部为0]`;
      }

      if ((product.topPocketSize < 0 || product.topPocketSize > 999.9)) {
        message += message + `[上口袋尺寸：要求大于等于0且小于等于999.9]`;
      }

      if ((product.bottomPocketSize < 0 || product.bottomPocketSize > 999.9)) {
        message += message + `[下口袋尺寸：要求大于等于0且小于等于999.9]`;
      }
    } else {
      product.topPocketSize = 0;
      product.bottomPocketSize = 0;
    }

    return message;
  }
}

